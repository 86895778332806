<!--PAGE-->
<section class="margin-content">

    <!--CONTAINER-->
    <div class="container-animated">
        <div class="row">


            <div class="hero-section">
                <!--
                <video autoplay loop muted class="hero-video">
                    <source src="assets/video/hero.mp4" type="video/mp4">
                Il tuo browser non supporta i video.
                </video>
                -->
                <img src="assets/img/herohome.jpg" class="hero-image">
                <div class="overlay"></div>
                <div class="hero-content">
<!--                    <img src="assets/img/sfondocomune.png" class="hero-logo">-->
                    <h1>{{'PAGES.HOME.HERO_TITLE'| translate}}</h1>
<!--                    <div class="search-bar">-->
<!--                        <input type="text" placeholder="{{ 'GENERAL.SEARCH' | translate}}" [(ngModel)]="searchText"-->
<!--                            (keyup.enter)="search()">-->
<!--                        <i class='bx bx-search' (click)="search()"></i>-->
<!--                    </div>-->
                    <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>
                </div>
            </div>



            <nav class="category-navigation">
                <a routerLink="/articoli">
                    <button>
                        <img src="assets/img/icona_articoli.png" class="">
                        <span>{{'PAGES.HOME.NEWS'| translate}}</span>
                    </button>
                </a>
                <a routerLink="/itinerari">
                    <button>
                        <i class='bx bx-pin'></i>
                        <span>{{'PAGES.HOME.ITINERARI'| translate}}</span>
                    </button>
                </a>
                <a routerLink="/articoli">
                    <button>
                        <img src="assets/img/icona_articoli.png" class="">
                        <span>{{'PAGES.HOME.RICETTE'| translate}}</span>
                    </button>
                </a>
            </nav>

            <!--CATEGORIE PUNTI DI INTRESSE-->
            <a routerLink="/categoria/{{c.id}}" class="col-lg-4 col-md-4 col-sm-12"
                *ngFor="let c of appService.categoriePuntiDiInteresse;" [style]="c.pubblicata ? '' : 'display: none'">
                <div class="card">
                    <div class="card-image">
                        <img [src]="c.pathCopertina">
                    </div>
                    <div class="card-content-transparent">
                        <div class="card-title">{{ c.nome }}</div>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <!--CHATBOT-->
    <div class="my-chat-floating-button">
        <button class="my-chat-btn" (click)="toggleChat()"><i class='bx bx-chat'></i></button>
    </div>

    <div class="my-chat-window" [class.open]="isChatOpen">
        <div class="my-chat-header">
            <span>Lulianum Chat Bot</span>
            <button (click)="toggleChat()" style="background: #fff;color: #000;border-radius: 20px;">x</button>
        </div>
        <div class="my-chat-body">
            <div class="my-chat-message my-chat-bot-message">{{ 'PAGES.HOME.CHAT.FIRST_MESSAGE' | translate }}</div>
            <div *ngFor="let message of messages" class="my-chat-message"
                [ngClass]="{'my-chat-bot-message': message.sender === 'bot', 'my-chat-user-message': message.sender === 'user'}">
                <div class="message-content">
                    <div *ngIf="message.img" class="image-container"
                        [style.background-image]="'url(' + message.img + ')'"></div>
                    <div class="text-container">
                        <span *ngIf="message.text">{{ message.text | translate }}</span>
                    </div>
                    <a *ngIf="message.url" [routerLink]="message.url" class="chat-message-arrow-button"></a>
                </div>
            </div>

        </div>
        <div class="my-chat-footer">
            <input type="text" class="my-chat-input" [(ngModel)]="userInput" (keyup.enter)="sendMessage()"
                placeholder="{{ 'PAGES.HOME.CHAT.WRITE_MEX' | translate }}">
            <button class="my-chat-btn-send" (click)="sendMessage()">{{ 'GENERAL.SEND' | translate }}</button>
        </div>
    </div>





</section>


<span *ngIf="!hideBanner">
    <div *ngIf="!isInStandaloneMode && installed | async" class="install-banner">
        <p>{{'GENERAL.INSTALL_PWA'|translate}}</p>
        <button (click)="install()">{{'GENERAL.INSTALL'|translate}}</button>
        <button (click)="hideBanner = true" class="close-button">&#10005;</button>
    </div>
</span>
