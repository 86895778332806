import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-articoli',
  templateUrl: './articoli.component.html',
  styleUrls: ['./articoli.component.scss']
})
export class ArticoliComponent {

  articoli: any[] = []; // Assumi che vengano caricati da qualche parte
  public error: string;
  public loading: boolean = true;

  constructor(public appService: AppService) {
    this.gatArticoli();
  }

  gatArticoli() {


    /*TEST MULTI IMMAGINI
    this.articoli = [
      {
        "id": 2,
        "idAnagrafica": 1,
        "titolo": "Articolo di test",
        "contenuto": "aaaaasaad",
        "pathCopertina": "https://retechiesegiugliano.publisys.it/storage/images/articolo/articolo_1700495282.png",
        "telefono": "3332233123",
        "email": "admin@retechiesegiugliano.publisys.it",
        "indirizzo": "Via Giacomo Matteotti",
        "dataPubblicazione": "2023-11-19T23:00:00.000000Z",
        "totaleLetture": 0,
        "premium": false,
        "pubblicato": true,
        "attivo": true,
        "dataModifica": "2023-11-20T15:48:02.000000Z",
        "dataCreazione": "2023-11-20T15:48:02.000000Z",
        "mediaarticolos": [
          {
            "id": 1,
            "idArticolo": 2,
            "titolo": "foto_comune.jpg",
            "path": "https://www.ktsfinance.com/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-24-at-16.00.29.jpeg",
            "attivo": true,
            "dataModifica": "2023-11-20T15:48:02.000000Z",
            "dataCreazione": "2023-11-20T15:48:02.000000Z"
          },
          {
            "id": 1,
            "idArticolo": 2,
            "titolo": "foto_comune.jpg",
            "path": "https://www.ktsfinance.com/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-21-at-16.38.12.jpeg",
            "attivo": true,
            "dataModifica": "2023-11-20T15:48:02.000000Z",
            "dataCreazione": "2023-11-20T15:48:02.000000Z"
          },
          {
            "id": 1,
            "idArticolo": 2,
            "titolo": "foto_comune.jpg",
            "path": "https://www.ktsfinance.com/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-17-at-15.37.08.jpeg",
            "attivo": true,
            "dataModifica": "2023-11-20T15:48:02.000000Z",
            "dataCreazione": "2023-11-20T15:48:02.000000Z"
          }
        ],
        "allegatoarticolos": [
          {
            "id": 1,
            "idArticolo": 2,
            "titolo": "estratto_verbale_di_proclamazione.pdf",
            "path": "https://retechiesegiugliano.publisys.it/storage/images/articolo/1700495273960.pdf",
            "attivo": true,
            "dataModifica": "2023-11-20T15:48:02.000000Z",
            "dataCreazione": "2023-11-20T15:48:02.000000Z"
          }
        ],
        "articolocategoriaarticolos": [
          {
            "id": 11,
            "idArticolo": 2,
            "idCategoriaArticolo": 1,
            "categoriaarticolo": {
              "id": 1,
              "nome": "News",
              "descrizione": "",
              "pathCopertina": "cat_articolo_1700474895.png",
              "pubblicata": true,
              "inDrawer": true,
              "attivo": true,
              "dataModifica": "2023-11-20T10:08:15.000000Z",
              "dataCreazione": "2023-11-20T10:08:15.000000Z",
              "idParent": 0
            }
          }
        ]
      }
    ];

    /*TEST 1 IMMAGINE
    this.articoli = [
      {
        "id": 2,
        "idAnagrafica": 1,
        "titolo": "Articolo di test",
        "contenuto": "aaaaasaad",
        "pathCopertina": "https://retechiesegiugliano.publisys.it/storage/images/articolo/articolo_1700495282.png",
        "telefono": "3332233123",
        "email": "admin@retechiesegiugliano.publisys.it",
        "indirizzo": "Via Giacomo Matteotti",
        "dataPubblicazione": "2023-11-19T23:00:00.000000Z",
        "totaleLetture": 0,
        "premium": false,
        "pubblicato": true,
        "attivo": true,
        "dataModifica": "2023-11-20T15:48:02.000000Z",
        "dataCreazione": "2023-11-20T15:48:02.000000Z",
        "mediaarticolos": [
          {
            "id": 1,
            "idArticolo": 2,
            "titolo": "foto_comune.jpg",
            "path": "https://www.ktsfinance.com/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-24-at-16.00.29.jpeg",
            "attivo": true,
            "dataModifica": "2023-11-20T15:48:02.000000Z",
            "dataCreazione": "2023-11-20T15:48:02.000000Z"
          }
        ],
        "allegatoarticolos": [
          {
            "id": 1,
            "idArticolo": 2,
            "titolo": "estratto_verbale_di_proclamazione.pdf",
            "path": "https://retechiesegiugliano.publisys.it/storage/images/articolo/1700495273960.pdf",
            "attivo": true,
            "dataModifica": "2023-11-20T15:48:02.000000Z",
            "dataCreazione": "2023-11-20T15:48:02.000000Z"
          }
        ],
        "articolocategoriaarticolos": [
          {
            "id": 11,
            "idArticolo": 2,
            "idCategoriaArticolo": 1,
            "categoriaarticolo": {
              "id": 1,
              "nome": "News",
              "descrizione": "",
              "pathCopertina": "cat_articolo_1700474895.png",
              "pubblicata": true,
              "inDrawer": true,
              "attivo": true,
              "dataModifica": "2023-11-20T10:08:15.000000Z",
              "dataCreazione": "2023-11-20T10:08:15.000000Z",
              "idParent": 0
            }
          }
        ]
      }
    ];*/

    /*
        return false;
    */

    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    this.loading = true;
    this.error = '';
    this.articoli = [];
    this.appService.apiService.getArticoli(1).then((data: []) => {
      console.log(data);
      this.articoli = data;
    }).catch((err) => {
      this.error = err
      console.log('error: ', err);
    }).finally(() => this.loading = false);
  }
}
